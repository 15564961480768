import { TextField, InputAdornment } from '@mui/material';
import React, { useState } from 'react';
import SearchItem from '../../common/assest/searchIcon.svg';

const SearchBar = ({ style, onSearchChange,searchValue,resetPage,placeholder}) => {
 
 
  const handleInputChange = (event) => {
    if (event.target.value.trim() !== "" || event.target.value === "") {
      onSearchChange(event.target.value); 
      resetPage()
    }
  
  };

  return (
    <TextField
      value={searchValue}
      onChange={handleInputChange}
      placeholder={placeholder}
      sx={{
        width: { sm: 250 },
      
        height: { xs: 45, sm: 45 },
        flexShrink: 0,
        borderRadius: '33px',
        background: '#F6F5F6',
        border: 'none',
        '& .MuiOutlinedInput-input': {
          height: '30%',
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: '33px',
          height: '100%',
          paddingRight: '10px',
          outline: 'none',
          border: 'none',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        ...style,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <img
              src={SearchItem}
              sx={{ color: '#000', opacity: '0.35' }}
              alt="Search Icon"
            />
          </InputAdornment>
        ),
        style: { height: '100%' },
      }}
    />
  );
};

export default SearchBar;

import React, { useEffect, useState } from 'react';
import './AdminManagement.css';
import ImgUploader from '../../common/imguploader/ImgUploader';
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { Button, Card, Grid, Menu, MenuList, Typography } from '@mui/material';
import CarbonAvtar from '../../common/assest/carbonavatar.svg';
import CharmenuIcon from '../../common/assest/charmenu.svg';
import { Link, useNavigate } from 'react-router-dom';
import AdminModal from './AdminModal';
import { useSelector } from 'react-redux';
import { addAdminApi, deleteApi, getAllAdminApi } from '../../api';
import { toast } from 'react-toastify';
import Paginations from '../../common/pagintaion/Pagination';
import Loader from '../../common/loader/Loader';
import alertDialog from '../../utils/alertDialog';

const AdminManagement = () => {
  const token = useSelector((state) => state?.user?.user?.token);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [adminData, setAdminData] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenuId, setOpenMenuId] = useState(null);

  const handleClick = (event, adminId) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuId(adminId); 
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenuId(null);  
  };

  // get all admin
  const handleAllAdmin = async () => {
    try {
      setLoading(true);
      const response = await getAllAdminApi({token,currentPage});
      // console.log("res",response);
      
      setAdminData(response?.data);
      setCurrentPage(response?.page);
      setTotalPage(response?.pages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleAllAdmin();
  }, [currentPage,change]);

  const validateEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  
  // add new admin
  const handleAddAdmin = async (admin) => {
    // if (!admin.email) {
    //   toast.warning("Email are required");
    //   return;
    // }
    // if (!admin.fullName) {
    //   toast.warning("Full Name are required");
    //   return;
    // }
    // if (!admin.password) {
    //   toast.warning("Password are required");
    //   return;
    // }
    // if (admin.password.length<8) {
    //   toast.warning("Password should be 8 characters long ");
    //   return;
    // }
    // if (!validateEmail(admin.email)) {
    //   toast.error("Please provide a valid email address.");
    //   return;
    // }
    try {
      setLoading(true);
      const response = await addAdminApi({ token, admin });
      setLoading(false);
      toast.success("New Admin Added");
     
      setChange(!change);
      closeModal();
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };


  // delete admin
  const handleDeleteUser = async (id) => {
    const confirmDelete = await alertDialog({
      title: 'Confirm Action',
      description: 'Are you sure you want to Delete this account?'
    });
    if(!confirmDelete){
      return
    }
    try {
      const response = await deleteApi({token, id});
      const successMsg = response?.message;
      if (Array.isArray(successMsg)) {
        successMsg.forEach((msg) => toast.success(msg));
      } else if (successMsg) {
        toast.success(successMsg);
      }
      setChange(!change);
    } catch (error) {
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  const handleViewUser = (admin) => {
    navigate(`/admin-management/update-profile`,{state:admin});
  };

  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Admin Management</span>,
  ];

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setCurrentPage(page);
  };
  
  return (
    <div className='admin-container'>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="bread-crum">
            {breadcrumbItems.map((item, index) => (
              <div className="all-breakcrum" key={index}>
                {item}
                {index < breadcrumbItems.length - 1 && " > "}
              </div>
            ))}
          </div>

          <div className='create-btn-container'>
            <Button 
              onClick={openModal}
              variant="contained"
              className="create-admin-btn"
              startIcon={<AddCircleOutlineOutlinedIcon className="create-admin-icon" />}
            >
              Create New Admin
            </Button>
          </div>

          <div className='admin-cards-container'>
            {adminData.length > 0 ? (
              adminData.map((admin) => (
                <Card
                  key={admin?._id}
                  className="custom-card-admin"
                  sx={{ overflow: "visible" }}
                >
                  <Grid container spacing={4} p={0} sx={{width:'500px'}}>
                    <Grid item xs={3} >
                      <div className="grid-item">
                        <img src={admin?.profile_picture||CarbonAvtar} alt='icon' style={{ height: '65px',width:'65px',borderRadius:'50%'}} />
                      </div>
                    </Grid>
                    <Grid item xs={6} onClick={() => handleViewUser(admin)}>
                      <div className="grid-item-info">
                        <div className="admin-info-name">{admin?.full_name.slice(0, 10)+ "..." || "N/A"}</div>
                        <div className='admin-info-mail'>{admin?.email.slice(0, 12) + "..."}</div>
                        <div className="admin-info-role">Role: {admin?.role || "Admin"}</div>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="grid-item-charmenu" key={admin?._id}>
                        <img
                          src={CharmenuIcon}
                          alt="menu-icon"
                          onClick={(e) => handleClick(e, admin?._id)}
                        />
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={openMenuId === admin?._id}  
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                          sx={{
                            '& .MuiPaper-root': {
                              borderRadius: '25px',
                              background: '#FFF',
                              // boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1);',
                              width: '200px',
                              height: '100px',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '10px',
                              alignContent: 'center',
                              justifyContent: 'left',
                              color: '#9A031E',
                              fontWeight: '600',
                              cursor: 'pointer',
                              // padding:"10px",
                              // outline: "none",
                            },
                          
                          }}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          <div onClick={()=>handleViewUser(admin)} className='view-user-account'>View User Account</div>
                          <div onClick={() => handleDeleteUser(admin?._id)} className='delete-user-account'>Delete User Account</div>
                        </Menu>
                      </div>
                    </Grid>
                  </Grid>
                </Card>
              ))
            ) : (
              <div style={{ textAlign: "center" }}>
                No Data Found
              </div>
            )}
          </div>

          <div style={{ display: 'flex', justifyContent: 'center',marginBottom:'10px' }}>
            <Paginations
              onPageChange={handlePageChange}
              currentPage={currentPage}
              totalPages={totalPage}
              disabled={loading}
            />
          </div>
        </>
      )}

      <AdminModal open={open} handleClose={closeModal} onSubmit={handleAddAdmin} />
    </div>
  );
}

export default AdminManagement;

import React from 'react'
import AddEditPlan from '../../common/AddEditPlans/AddEditPlan'
import { Link } from 'react-router-dom';

const ChefEditSubscription = () => {

    const breadcrumbItems = [
        <Link key="home" to="/dashboard" className="All-color-breakcurm">
          Home
        </Link>,
        <Link key="home" to="/dashboard/chefs" className="All-color-breakcurm">
         Chef’s 
      </Link>,
        <Link key="home" to="/dashboard/chefs/subscription-management" className="All-color-breakcurm">
        Subscription
      </Link>,
        <Link key="home" to="/dashboard/chefs/subscription-management/manage-your-plans" className="All-color-breakcurm">
        Manage Your Plans
      </Link>,
        
        <span style={{ fontWeight: "bolder" }}>&nbsp;Edit Subscription</span>,
      ];
  return (
    <div>
     <div className="bread-crum">
            {breadcrumbItems.map((item, index) => (
              <div className="all-breakcrum" key={index}>
                {item}
                {index < breadcrumbItems.length - 1 && " > "}
              </div>
            ))}
          </div>

          <AddEditPlan/>
    </div>
  )
}

export default ChefEditSubscription
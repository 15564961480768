import React, { useState } from "react";
import TicketDrawer from "./TicketDrawer";
import "./Ticket.css";
import formatDate from "../../utils/birthDateConversion";

const UnResolved = ({ unresolvedTickets,onTicketSelect }) => {
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const [selectedTicket, setSelectedTicket] = useState(null);

  // const handleTicketClick = (ticket) => {
  //   setSelectedTicket(ticket);
  //   setIsDrawerOpen(true);
  // };

  // const handleCloseDrawer = () => {
  //   setIsDrawerOpen(false);
  //   setSelectedTicket(null);
  // };

  // const handleStatusChange = (newStatus) => {
  //   if (selectedTicket) {
    
  //     console.log(`Ticket status changed to: ${newStatus}`);
  //   }
  // };
// console.log("first",unresolvedTickets)
  return (
    <>
      {unresolvedTickets.length > 0 ? (
        unresolvedTickets.map((ticket) => (
          <div
            className="ticket-container"
            style={{ marginTop: "20px" }}
            key={ticket?._id}
            onClick={() => onTicketSelect(ticket)}
          >
            <div className="ticket-card">
              <div className="ticket-column">
                <div className="ticket-box title">{ticket?.title || "N/A"}</div>
                  <div className="ticket-box datess">{ticket?.created_at ?  formatDate(ticket?.created_at):"N/A"}</div>
                <div className="ticket-box ticket-description">
                  {ticket?.description || "N/A"}
                </div>
                <div className="ticket-box status-priority-container">
                  <div className="ticket-priority">
                    Priority:{" "}
                    <span style={{ color: "#EA1D26" }}>{ticket?.priority}</span>
                  </div>
                  <div className="ticket-status">
                    Status:{" "}
                    <span style={{ color: "#FFC107" }}>{ticket?.status}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div style={{ textAlign: "center" }}>No Unresolved Tickets Found</div>
      )}

      {/* Use the TicketDrawer and pass necessary props */}
      {/* <TicketDrawer
        isOpen={isDrawerOpen}
        ticket={selectedTicket}
        onClose={handleCloseDrawer}
        onStatusChange={handleStatusChange}
      /> */}
    </>
  );
};

export default UnResolved;

import React from 'react'
import Card1 from '../../common/card1/Card1'
import { Link, useNavigate } from 'react-router-dom';
import SearchBar from '../../common/searchbar/SearchBar';
import TableCard from '../../common/tableCard/TableCard';
import TableComp from '../../common/tablecomp/TableComp';
import userimg from "../../common/assest/usrimg.png";
import SubscriptionRow from '../../common/tablerow/SubscriptionRow';
import { Button } from '@mui/material';
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import './chefSubscription.css'

const ChefSubscription = () => {
    

  const navigate=useNavigate();


  const titles = [
    { title: "Chef Name" },
    { title: "Date of Subscription" },
    { title: "Subscription Plan" },
    { title: "Amount" },
   
  ];


     const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="home" to="/dashboard/chefs" className="All-color-breakcurm">
     Chef’s 
  </Link>,
    
    <span style={{ fontWeight: "bolder" }}>&nbsp;Subscription</span>,
  ];
  const style={
    minHeight:"95px",
    padding:"10px",
    cursor: 'default'
  }

  const rows = [
    {
      chefImage: userimg,
      chefName: "Jonathan Williams",
      earnings: "$0.00/mo",
      joineddate: "05/16/2022",
      subs: "free",
    },
    {
      chefImage: userimg,
      chefName: "Jonathan Williams",
      earnings: "$0.00/mo",
      joineddate: "05/16/2022",
      subs: "free",
    },
    
  ];

  return (
    <div>
          <div className="bread-crum">
            {breadcrumbItems.map((item, index) => (
              <div className="all-breakcrum" key={index}>
                {item}
                {index < breadcrumbItems.length - 1 && " > "}
              </div>
            ))}
          </div>

          <div className='chef-details-card-container-subs chef-subscriptions-btn'>
           <div className='chef-subs-card'>
              <Card1 value="67890" text="Total Subscriptions" style={style}/>
              <Card1 value="67890" text="Revenue Generated"  style={style}/>
           </div>
            <Button
                onClick={()=>navigate('/dashboard/chefs/subscription-management/manage-your-plans')}
                variant="contained"
                className="create-admin-btn"
                startIcon={<AddCircleOutlineOutlinedIcon className="create-admin-icon" />}
              >
                Manage Your Plans
              </Button>
          </div>

          <TableCard>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            padding: "20px",
            fontFamily: "Rosario",
            fontWeight: 600,
            fontSize: "20px",
          }}
        >
          <div>Subscribed Users</div>
          <div>
            <SearchBar />
          </div>
        </div>

        <TableComp title={titles} emptyEColumnCount={1}>
          {rows.map((item) => (
            <SubscriptionRow  row={item} />
          ))}
        </TableComp>
      </TableCard>

    </div>
  )
}

export default ChefSubscription
import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import FoodImg from '../assest/food.png';
import TickIcon from '../assest/tickIcon.svg'
import CrossIcon from '../assest/crossIcon.svg'
import MessageIcon from '../assest/messageIcon.svg'
import Photos1 from '../assest/photos2.png'
import Photos2 from '../assest/photos1.png'
import './EventDetailsbtn.css';
import { getChefsGListApi, getChefsMenuItemApi } from '../../api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';




const EventDetailsbtn = ({data}) => {

  // console.log("data",data)
  const token = useSelector((state) => state?.user?.user?.token);
  // console.log("evnts btn",data)
  const [activeTab, setActiveTab] = useState(0);
  const [menuActiveTab, setMenuActiveTab] = useState(0);
  const [loading , setLoading]=useState(false)
  const [guestListItem , setGuestListItem]=useState([])
  const [menuItemList ,setMenuItemList]=useState([])


  const handleClick = (index) => {
    setActiveTab(index);
  };

  const fetchGuestList=async()=>{
    try {
       setLoading(true)
        const response= await getChefsGListApi({token,eid:data?._id})
        // console.log("gue",response);
        setGuestListItem(response)
        setLoading(false)
    } catch (error) {
      setLoading(false)
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  }

  const fetchMenuList=async()=>{
    try {
       setLoading(true)
       if(data?.chef?._id){
         const response= await getChefsMenuItemApi({token,eid:data?._id,cid:data?.chef?._id})
         setMenuItemList(response)
       }else{
        const response= await getChefsMenuItemApi({token,eid:data?._id,cid:data?.chef})
        setMenuItemList(response)
       }
        // console.log("gue",response);
        // setMenuItemList(response)
        setLoading(false)
    } catch (error) {
      setLoading(false)
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  }

  
  useEffect(()=>{
    if(activeTab===3){
      fetchGuestList()
    }else if(activeTab===1){
      fetchMenuList()
    }
    
  },[activeTab])

  const imageKeys = [
    "exterior_photos",
    "dinning_room_photos",
    "bar_area_photos",
    "outdoor_patio_photos",
    "food_preparation_photos"
  ];

  const allImages = (data ? imageKeys
    .filter(key => Array.isArray(data[key])) // Check if it's an array
    .map(key => data[key]) // Map to arrays
    .flat() // Flatten arrays into a single array
    : []); // 

  return (
    <div>
      {/* Render buttons */}
      <div className="five-btn-container">
        <Button
          onClick={() => handleClick(0)}
          className="responsive-btn"
          sx={{
            width: "168px",
            height: "65px",
            borderRadius: "100px",
            textTransform: "none",
            border: "1px solid #DCDCDC",
            backgroundColor: activeTab === 0 ? "#EA1D26" : "#FFF",
            color: activeTab === 0 ? "#FFF" : "#C6C6C6",
            "&:hover": { backgroundColor: "#EA1D26" },
          }}
        >
          About Listing
        </Button>
        <Button
          onClick={() => handleClick(1)}
          className="responsive-btn"
          sx={{
            width: "168px",
            height: "65px",
            border: "1px solid #DCDCDC",
            borderRadius: "100px",
            textTransform: "none",
            backgroundColor: activeTab === 1 ? "#EA1D26" : "#FFF",
            color: activeTab === 1 ? "#FFF" : "#C6C6C6",
            "&:hover": { backgroundColor: "#EA1D26" },
          }}
        >
          Menu
        </Button>
        <Button
          onClick={() => handleClick(2)}
          className="responsive-btn"
          sx={{
            width: "168px",
            height: "65px",
            border: "1px solid #DCDCDC",
            borderRadius: "100px",
            textTransform: "none",
            backgroundColor: activeTab === 2 ? "#EA1D26" : "#FFF",
            color: activeTab === 2 ? "#FFF" : "#C6C6C6",
            "&:hover": { backgroundColor: "#EA1D26" },
          }}
        >
          Rules
        </Button>
        <Button
          onClick={() => handleClick(3)}
          className="responsive-btn"
          sx={{
            width: "168px",
            height: "65px",
            border: "1px solid #DCDCDC",
            borderRadius: "100px",
            textTransform: "none",
            backgroundColor: activeTab === 3 ? "#EA1D26" : "#FFF",
            color: activeTab === 3 ? "#FFF" : "#C6C6C6",
            "&:hover": { backgroundColor: "#EA1D26" },
          }}
        >
          Guest Lists
        </Button>
        <Button
          onClick={() => handleClick(4)}
          className="responsive-btn"
          sx={{
            width: "168px",
            height: "65px",
            border: "1px solid #DCDCDC",
            borderRadius: "100px",
            textTransform: "none",
            backgroundColor: activeTab === 4 ? "#EA1D26" : "#FFF",
            color: activeTab === 4 ? "#FFF" : "#C6C6C6",
            "&:hover": { backgroundColor: "#EA1D26" },
          }}
        >
          Photos
        </Button>
      </div>

      {/* Tab content */}
      <div
        style={{ marginTop: "25px", padding: "10px" }}
        className="tab-wrappers-data"
      >
        {/*about listing*/}
        {activeTab === 0 && (
          <>
            <div className="section-header">Name of Your Place</div>
            <p className="section-description">{data?.name || "N/A"}</p>

            <div className="section-header">Dining Style</div>
            <p className="section-description">
              {data?.dinning_style || "N/A"}
            </p>

            <div className="section-header">Describe your place to guests</div>
            <p className="section-description">{data?.description || "N/A"}</p>

            <div className="section-header">Available Seats</div>
            <p className="section-description">{data?.available_seats || 0}</p>

            <div className="section-header">Street Address</div>
            <p className="section-description">
              {data?.street_address || "N/A"}
            </p>

            <div className="section-header">City</div>
            <p className="section-description">{data?.city || "N/A"}</p>

            <div className="section-header">State</div>
            <p className="section-description">{data?.state || "N/A"}</p>

            <div className="section-header">Zip</div>
            <p className="section-description">{data?.zipcode || "N/A"}</p>
          </>
        )}

        {/*menu*/}

        {activeTab === 1 && (
          <>
            <div className="course-tabs">
              <span
                onClick={() => setMenuActiveTab(0)}
                style={{ color: menuActiveTab === 0 ? "#EA1D26" : "#000" }}
              >
                Starter
              </span>
              <span
                onClick={() => setMenuActiveTab(1)}
                style={{ color: menuActiveTab === 1 ? "#EA1D26" : "#000" }}
              >
                Main Course
              </span>
              <span
                onClick={() => setMenuActiveTab(2)}
                style={{ color: menuActiveTab === 2 ? "#EA1D26" : "#000" }}
              >
                Dessert
              </span>
            </div>

            <div className="menu-card-container" style={{ marginTop: "25px" }}>
              {menuActiveTab === 0 && (
                <>
                  {menuItemList?.length > 0 ? (
                    menuItemList?.map((item) => (
                      <div className="guest-menu--card" key={item?._id}>
                        <img
                          src={item?.image || null}
                          alt="menu-item-image"
                          className="guest-menu--card-image"
                        />
                        <div className="guest-menu--card-content">
                          <span className="guest-menu--card-name">
                            {item?.name || "N/A"}
                          </span>
                          <span className="guest-menu--card-description">
                            {item?.description || "N/A"}
                          </span>
                          <span className="guest-menu--card-price">
                            {`$${item?.price}` || 0}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div style={{ textAlign: "center" }}>No Data Found</div>
                  )}
                </>
              )}

              {/* {menuActiveTab === 1 && (
                <>
                 {starter.map((item)=>(

              <div className="guest-menu--card">

                <img src={item.imageUrl} alt="Card Image" className="guest-menu--card-image" />
                <div className="guest-menu--card-content">
                  <span className="guest-menu--card-name">{item.name}</span>
                  <span className="guest-menu--card-description">{item.description}</span>
                  <span className="guest-menu--card-price">{item.price}</span>
                </div>
              </div>
              ))}
                </>
              )} */}

              {/* {menuActiveTab === 2 && (
                <>
                  <div className="guest-menu--card">
                    <img src={FoodImg} alt="Card Image" className="guest-menu--card-image" />
                    <div className="guest-menu--card-content">
                      <span className="guest-menu--card-name">Cheesecake</span>
                      <span className="guest-menu--card-description">Creamy cheesecake with strawberries.</span>
                      <span className="guest-menu--card-price">$7.99</span>
                    </div>
                  </div>
                </>
              )} */}
            </div>
          </>
        )}

        {/*rules*/}
        {activeTab === 2 && (
          <div>
            {data?.rules?.length > 0 ? (
            data.rules.map((rule, index) => (
              <div className="rules" key={index}>
                <div>{rule}</div>
                <div>
                  {/* Always display TickIcon for all rules */}
                  <img src={TickIcon} alt="Tick" />
                </div>
              </div>
            ))
          ) : (
            ""
            // <div></div>
          )}
    {/* Static details if needed */}
    <div className="rules">How much notice do you need before a guest arrives</div>
    <span className="rules-txt">{data?.notice_before_guests_arrive||0}</span>
    <div className="rules">How much far in advance the guest can book?</div>
    <span className="rules-txt">{data?.advance_booking_days||0}</span>
    <div className="rules">Manage Availability</div>
    <span className="rules-txt">
             {
              data?.event_date ? 
              new Date(data?.event_date || "-").toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
              :"-"
              } | {data?.event_start_time||""} to {data?.event_end_time||""}
    </span>
  </div>
)}


        {/* guests */}
        {activeTab === 3 && (
          <div className="guest-list-container">
            {guestListItem.length > 0 ? (
              guestListItem?.map((guest, index) => (
                <div className="guest-list-card" key={index}>
                  <div className="guest-list-content">
                    <div className="guest-details">
                      <span className="guest-name">{guest?.user?.full_name || "N/A"}</span>
                      <span className="guest-total">
                        {guest?.people || 0} Total Guests
                      </span>
                      <span className="guest-id">
                        Booking ID: {guest?.bookingId || "N/A"}
                      </span>
                    </div>
                    <div className="guest-image">
                      <img src={MessageIcon} alt="icon" />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div style={{ textAlign: "center",margin:'auto' }}>No Data Found</div>
            )}
          </div>
        )}

        {/* imges */}
        {activeTab === 4 && (
        <div className="image-grid-container">
          {allImages?.map((image, index) => (
            <div className="image-grid-item" key={index}>
              <img src={image} alt={`Image ${index}`} className="image-grid-img" loading='lazy'/>
            </div>
          ))}
        </div>
        )}
    
      </div>
    </div>
  );
};

export default EventDetailsbtn;

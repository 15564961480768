import React, { useState } from "react";
import RulesRow from "../../common/tablerow/RulesRow";
import SearchBar from "../../common/searchbar/SearchBar";
import TableCard from "../../common/tableCard/TableCard";
import { Button, TableCell, TableRow } from "@mui/material";
import TableComp from "../../common/tablecomp/TableComp";
import { Link } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import RulesModal from "../../common/modal/RulesModal";
import { TextFields } from "@mui/icons-material";
import CustomBtn1 from "../../common/customBtn1/CustomBtn1";

const RulesManagement = () => {
  const [searchQuery,setSearchQuery]=useState("")
  const [resetting,setResetting]=useState(false)

  const [open, setOpen] = useState(false);

  const handleCreateRule = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const RulesData = [{}];

  const styles = {
    width: "275px",
  };
  const btnStyle = {
    with: "80%",
  };

  const titles = [{ title: "Sr No" }, { title: "Rules" },{title:"Action"},];

  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Rules Management</span>,
  ];

  const handleSearchChange = (newValue) => {
    setSearchQuery(newValue);
  };

 
 

  const resetPage = () => {
    setResetting(true); 
    // setCurrentPage(1);
  };


 

  return (
    <div>
      <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>

      <div className="whole-card-wrapper-notification">
        <TableCard>
          <div className="push-notification-txt">Rule Management</div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center",
              padding: "20px",
              gap: "10px",
            }}
          >
            <div>
              <SearchBar style={styles}  placeholder="Search..." onSearchChange={handleSearchChange}
              resetPage={resetPage}
              />
            </div>

            <div>
              <Button
                onClick={handleCreateRule}
                sx={{ width: "200px" }}
                variant="contained"
                className="create-admin-btn"
                // startIcon={<AddCircleOutlineOutlinedIcon className="create-admin-icon" />}
              >
                Create New Rule
              </Button>
            </div>
          </div>

          <TableComp title={titles} >
            {RulesData.length > 0 ? (
              RulesData.map((item, idx) => (
                <RulesRow
                  key={item?._id}
                  index={idx}
                  row={item}
                  //  deleteNotification={()=>handleDeleteNotification(item?._id)}
                  //  edit={()=>handleEditNotification(item)}
                />
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={titles.length}
                  align="center"
                  sx={{ fontFamily: "Rosario", fontWeight: "600" }}
                >
                  No Data Found
                </TableCell>
              </TableRow>
            )}
          </TableComp>
        </TableCard>
      </div>

      <RulesModal open={open} handleClose={handleClose}>
        <div className="create-modal-heading">
          <div>{"Create New Rule"}</div>
          <ClearIcon onClick={handleClose} style={{ cursor: "pointer" }} />
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <textarea
            placeholder="Enter here"
            //   value={formData.bio}
            //   name="bio"
            //   onChange={handleInputChange}
            rows={3}
            cols={89}
            style={{
              width: "90%",
              border: "none",
              backgroundColor: "#F0F0F0",
              borderRadius: "25px",
              resize: "none",
              outline: "none",
              textIndent: "20px",
              paddingTop: "20px",
              // opacity:"0.8",
              fontWeight: "400",
              color: "#120B1C",
              fontFamily: "Rosario",
            }}
          />
        </div>

        <div style={{ marginTop: "30px" }}>
          <div
            style={{
              width: "90%",
              display: "flex",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <CustomBtn1 text="Add" />
          </div>
        </div>
      </RulesModal>
    </div>
  );
};

export default RulesManagement;

import { Button } from '@mui/material';
import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import './chefSubscription.css'
import TableCard from '../../common/tableCard/TableCard';
import SearchBar from '../../common/searchbar/SearchBar';
import TableComp from '../../common/tablecomp/TableComp';
import SubscriptionPlanRow from '../../common/tablerow/SubscriptionPlanRow';

const ChefManagePlan = () => {
const navigate=useNavigate()

    const titles = [
        { title: "Sr No" },
        { title: "Subscription Plans" },
        { title: "Amount" },
        { title: "Status" },
       
      ];

      const breadcrumbItems = [
        <Link key="home" to="/dashboard" className="All-color-breakcurm">
          Home
        </Link>,
        <Link key="home" to="/dashboard/chefs" className="All-color-breakcurm">
         Chef’s 
      </Link>,
        <Link key="home" to="/dashboard/chefs/subscription-management" className="All-color-breakcurm">
        Subscription
      </Link>,
        
        <span style={{ fontWeight: "bolder" }}>&nbsp;Manage Your Plans</span>,
      ];
      const rows = [
        {
        //   chefImage: userimg,
          chefName: "Jonathan Williams",
          earnings: "$0.00/mo",
          joineddate: "05/16/2022",
          subs: "free",
          status:"Active"
        },
        {
        //   chefImage: userimg,
          chefName: "Jonathan Williams",
          earnings: "$0.00/mo",
          joineddate: "05/16/2022",
          subs: "free",
          status:"Active"
        },
        
      ];

      const handleView=()=>{
         navigate('/dashboard/chefs/subscription-management/manage-your-plans/view-subscriptions')
      }

      const handleEdit=()=>{
        navigate('/dashboard/chefs/subscription-management/manage-your-plans/edit-subscriptions')
      }

      
  return (
    <div>
           <div className="bread-crum">
            {breadcrumbItems.map((item, index) => (
              <div className="all-breakcrum" key={index}>
                {item}
                {index < breadcrumbItems.length - 1 && " > "}
              </div>
            ))}
          </div>

         <div className='manage-pln'>
         <Button
                onClick={()=>navigate('/dashboard/chefs/subscription-management/manage-your-plans/add-subscriptions')}
                variant="contained"
                className="create-admin-btn"
                startIcon={<AddCircleOutlineOutlinedIcon className="create-admin-icon" />}
              >
               Add Subscription
              </Button>
         </div>

         <TableCard>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            padding: "20px",
            fontFamily: "Rosario",
            fontWeight: 600,
            fontSize: "20px",
          }}
        >
          <div>Subscription Plans</div>
          <div>
            <SearchBar />
          </div>
        </div>

        <TableComp title={titles} emptyEColumnCount={1}>
          {rows.map((item,index) => (
            <SubscriptionPlanRow  row={item} idx={index} 
              edit={handleEdit}  
              view={handleView}
            />
          ))}
        </TableComp>
      </TableCard>
             
    </div>
  )
}

export default ChefManagePlan
import React, { useEffect, useState } from "react";

import Card1 from "../../common/card1/Card1";
import SearchBar from "../../common/searchbar/SearchBar";
import TableCard from "../../common/tableCard/TableCard";
import TableComp from "../../common/tablecomp/TableComp";
import ChefRow from "../../common/tablerow/ChefRow";

import { Link, useLocation, useParams } from "react-router-dom";
import EarningsRow from "../../common/tablerow/EarningsRow";
import EventsRows from "../../common/tablerow/EventsRow";
import GuestsEventRow from "../../common/tablerow/GuestsEventRow";
import { useSelector } from "react-redux";
import { getGuestsEventsApi } from "../../api";
import { toast } from "react-toastify";
import { TableCell, TableRow } from "@mui/material";
import Loader from "../../common/loader/Loader";

const GuestsEvents = () => {
  const token = useSelector((state) => state?.user?.user?.token);
  const location=useLocation();
  // const allData=location?.state
  // const ids=location?.state?.user_id

  // console.log("guest",ids);
  
  const {id}=useParams()

const [guestsEventData,setGuestsEventData]=useState([])
const [guestNameAndId,setGuestNameAndId]=useState({
  name:"",
  id:null
})
const [eventCount,setEventCount]=useState(0)
const [loading,setLoading]=useState(false)

const [currentPage, setCurrentPage] = useState(1);
const [totalPage, setTotalPage] = useState();
const [myTimout,setMyTimeOut]=useState()
const [searchQuery,setSearchQuery]=useState('');
const [resetting, setResetting] = useState(false); 



const handleGuestsEvents=async()=>{
  try {
    if (!resetting) setLoading(true);
    const response=await getGuestsEventsApi({token,id,currentPage,searchQuery})
    setGuestsEventData(response?.events)
    setEventCount(response?.total_count_event)
    setLoading(false)
    setGuestNameAndId({
      name:response?.user_name,
      id:response?.user_id
    })
    setCurrentPage(response?.page);
    setTotalPage(response?.pages);
  } catch (error) {
    setLoading(false)
    const errorMessage = error?.data?.errors;
    if (Array.isArray(errorMessage)) {
      errorMessage.forEach((msg) => toast.error(msg));
    } else if (errorMessage) {
      toast.error(errorMessage);
    }
  }
}

useEffect(() => {
  handleGuestsEvents();
}, [currentPage]);

useEffect(() => {
  if (searchQuery.trim() == "") {
    handleGuestsEvents();
  } else {
    clearTimeout(myTimout);
    setMyTimeOut(setTimeout(handleGuestsEvents, 500));
  }
}, [searchQuery]);


const handleSearchChange = (newValue) => {
  setSearchQuery(newValue);
};

const handlePageChange = (page) => {
  setCurrentPage(page); 
};

const resetPage = () => {
  setResetting(true); 
  setCurrentPage(1);
};

    const style = {
    minHeight: "95px",
    paddingTop: "22px",
    cursor: "default",
  };

  const titles = [
    { title: "Sr No" },
    { title: "Event Name" },
    { title: "Address" },
    { title: "Date" },
    { title: "Attendees" },
    { title: "Earnings" },
  ];


  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="home" to="/dashboard/guests" className="All-color-breakcurm">
      Guest’s
    </Link>,
    <Link
      key="home"
      to="/dashboard/guests/all-guests"
      className="All-color-breakcurm"
    >
      Guest’s Details
    </Link>,
    <Link
      key="home"
      to={`/dashboard/guests/all-guests/guests-information/${guestNameAndId?.id}`}
      className="All-color-breakcurm"
    >
     {guestNameAndId?.name||"N/A"}
    </Link>,

    <span style={{ fontWeight: "bolder" }}>&nbsp;Events</span>,
  ];


  return (
    <div>

      {loading ? (
        <Loader/>
      ):(
            <>
              <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>

      <div className="chef-details-card-container">
        <Card1 value={eventCount} text="Total Events  >" style={style} />
      </div>

      <TableCard>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            padding: "20px",
            fontFamily: "Rosario",
            fontWeight: 600,
            fontSize: "20px",
             gap:'10px'
          }}
        >
          <div>Events Details</div>
          <div>
          <SearchBar onSearchChange={handleSearchChange} searchValue={searchQuery}
            resetPage={resetPage} placeholder="Search by event name"
            />
          </div>
        </div>

        <TableComp title={titles}
          currentPage={currentPage}
          totalPage={totalPage}
          onPageChange={handlePageChange}
          loading={loading}
        >
         {guestsEventData?.length > 0 ? (
            guestsEventData.map((item,idx) => (
              <GuestsEventRow 
              userId={guestNameAndId?.id}
              username={guestNameAndId?.name}
              row={item}
              key={item?._id} 
              index={idx}
              // deleteUser={()=>handleDeleteUser(item?._id)}
              // suspendUser={()=>handleSuspendUser(item?._id)}
               />
            ))
          ):(
            <TableRow>
            <TableCell colSpan={titles.length} align="center" sx={{fontFamily:'Rosario',fontWeight:"600"}}>
              No Data Found
            </TableCell>
          </TableRow>
          )}
          </TableComp>
      </TableCard>
            
            </>
      )}
    
    </div>
  );
};

export default GuestsEvents;

import React, { useState } from 'react'
import './AddEditPlan.css'
const ViewSubs = () => {

      const [isActive, setIsActive] = useState(false);
    
        const toggleState = () => {
          setIsActive((prevState) => !prevState);
        };


  return (
    <div className='view-sub-page'>
        <div className='Plan-name'>
              Subscription Plan Name
        </div>
        <span className='pln-desc'>Plan 1</span>
        <div  className='Plan-name'>
        Original Price
        </div>
        <span className='pln-desc'>Plan 1</span>
        <div  className='Plan-name'>
        Discounted Price
        </div>
        <span className='pln-desc'>Plan 1</span>
        <div  className='Plan-name'>
        Features
        </div>
        <span className='pln-desc'>Lorem ipsum dolor sit amet consectetur. Ut facilisi pretium non fermentum senectus id adipiscing quam. Mattis dolor ut ut cum. Eget congue vitae sed nisl purus. Dis penatibus porta blandit id. Orci ut cursus tempor convallis integer ut donec. Molestie aliquet leo sit orci sed pellentesque. </span>

        <div style={{ display: "flex", alignItems: "center", gap: "10px" ,justifyContent:'start',marginTop:"25px"}}>
            <div
                className={`toggle-switch ${isActive ? "active" : ""}`}
                onClick={toggleState}
            >
                <div className="toggle-knob"></div>
            </div>
            <span>{isActive ? "Active" : "Inactive"}</span>
         </div>
    </div>
  )
}

export default ViewSubs
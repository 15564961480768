import React, { useState } from "react";
import "./AddEditPlan.css";
import CustomBtn1 from "../customBtn1/CustomBtn1";
import CustomBtn from "../custombtn/CustomBtn";
import { Box, TextField, Typography } from "@mui/material";

const AddEditPlan = () => {

    const [isActive, setIsActive] = useState(false);

    const toggleState = () => {
      setIsActive((prevState) => !prevState);
    };

    
  const style = {
    background: "White",
    color:"#000",
    border: "1px solid #EAEAEA"
  };
  return (
    <div>
      <div className="add-card-sub">
        <div className="susb-plan-name-container">
          <Box sx={{ marginTop: "25px", width: "100%" }}>
            <Typography
              sx={{
                marginBottom: "8px",
                fontSize: "17px",
                fontWeight: "600",
                textIndent: "10px",
                fontFamily: "Rosario",
              }}
            >
              Subscription Plan Name
            </Typography>
            <TextField
              type="text"
              placeholder="Enter here"
              name=""
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                },
              }}
              InputProps={{
                style: {
                  borderRadius: "28px",
                  backgroundColor: "#F0F0F0",
                  fontSize: "14px",
                  fontFamily: "Rosario",
                },
              }}
            />
          </Box>
        </div>

        <div className="orginal-discount">
          <div className="orginal-discount-container">
            <Box sx={{ marginTop: "25px", width: "100%" }}>
              <Typography
                sx={{
                  marginBottom: "8px",
                  fontSize: "17px",
                  fontWeight: "600",
                  textIndent: "10px",
                  fontFamily: "Rosario",
                }}
              >
                Original Price
              </Typography>
              <TextField
                type="text"
                placeholder="Enter here"
                name=""
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: "28px",
                    backgroundColor: "#F0F0F0",
                    fontSize: "14px",
                    fontFamily: "Rosario",
                  },
                }}
              />
            </Box>
          </div>
          <div className="orginal-discount-container">
            <Box sx={{ marginTop: "25px", width: "100%" }}>
              <Typography
                sx={{
                  marginBottom: "8px",
                  fontSize: "17px",
                  fontWeight: "600",
                  textIndent: "10px",
                  fontFamily: "Rosario",
                }}
              >
                Discounted Price
              </Typography>
              <TextField
                type="text"
                placeholder="Enter here"
                name=""
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: "28px",
                    backgroundColor: "#F0F0F0",
                    fontSize: "14px",
                    fontFamily: "Rosario",
                  },
                }}
              />
            </Box>
          </div>
        </div>

        <div className="susb-plan-name-container">
          <Box sx={{ marginTop: "25px", width: "100%" }}>
            <Typography
              sx={{
                marginBottom: "8px",
                fontSize: "17px",
                fontWeight: "600",
                textIndent: "10px",
                fontFamily: "Rosario",
              }}
            >
              Discounted Price
            </Typography>

            <textarea
              placeholder="Enter here"
              rows={3}
              cols={89}
              style={{
                width: "100%",
                border: "none",
                backgroundColor: "#F0F0F0",
                borderRadius: "25px",
                resize: "none",
                outline: "none",
                textIndent: "20px",
                paddingTop: "20px",
                // opacity:"0.8",
                fontWeight: "400",
                color: "#120B1C",
                fontFamily: "Rosario",
                height: "120px",
              }}
            />
          </Box>
        </div>

        <div style={{ display: "flex", alignItems: "center", gap: "10px" ,justifyContent:'center',marginTop:"25px"}}>
            <div
                className={`toggle-switch ${isActive ? "active" : ""}`}
                onClick={toggleState}
            >
                <div className="toggle-knob"></div>
            </div>
            <span>{isActive ? "Active" : "Inactive"}</span>
         </div>
        
        <div className="add-save-subs-btn">
          <CustomBtn text="Save Changes" />
          <CustomBtn text="Reset" custmstyle={style} />
        </div>
      </div>
    </div>
  );
};

export default AddEditPlan;

import React, { useState } from "react";
import TicketDrawer from "./TicketDrawer"; // Import the common drawer
import "./Ticket.css";
import formatDate from "../../utils/birthDateConversion";

const AllTicket = ({ allTicketData,onTicketSelect  }) => {

 
  // console.log("ab",allTicketData)
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const [selectedTicket, setSelectedTicket] = useState(null);

  // const handleTicketClick = (ticket) => {
  //   setSelectedTicket(ticket);
  //   setIsDrawerOpen(true);
  // };

  // const handleCloseDrawer = () => {
  //   setIsDrawerOpen(false);
  //   setSelectedTicket(null);
  // };



  return (
    <>
      {allTicketData.length > 0 ? (
        allTicketData.map((ticket) => (
          <div
            className="ticket-container"
            style={{ marginTop: "20px" }}
            key={ticket?._id}
            onClick={() => onTicketSelect(ticket)}
          >
            <div className="ticket-card">
              <div className="ticket-column">
                <div className="ticket-box title">{ticket?.title || "N/A"}</div>
                <div className="ticket-box datess">{ticket?.created_at ?  formatDate(ticket?.created_at):"N/A"}</div>
                <div className="ticket-box ticket-description">
                  {ticket?.description || "N/A"}
                </div>
                <div className="ticket-box status-priority-container">
                  <div className="ticket-priority">
                    Priority:{" "}
                    <span style={{ color: "#EA1D26" }}>
                      {ticket?.priority || "High"}
                    </span>
                  </div>
                  <div className="ticket-status">
                    Status:{" "}
                    <span
                      style={{
                        color: ticket?.status === "open" ? "#FFC107" : "#009048",
                      }}
                    >
                      {ticket?.status || ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div style={{ textAlign: "center" }}>No Ticket Found</div>
      )}

      {/* Use the common TicketDrawer */}
      {/* <TicketDrawer
        isOpen={isDrawerOpen}
        ticket={selectedTicket}
        onClose={handleCloseDrawer}

      /> */}
    </>
  );
};

export default AllTicket;

import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import SendIcon from "@mui/icons-material/Send";
import { toast } from "react-toastify";

const TicketDrawer = ({ isOpen, ticket, messages, onClose, handleSendMessage, handleTicketClose, handleMessageData }) => {
  const [inputValue, setInputValue] = useState("");

  // Fetch messages when ticket is opened
  useEffect(() => {
    if (isOpen && ticket?._id) {
      handleMessageData(ticket?._id);  
    }
  }, [ticket, isOpen]);

  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    if (newStatus === "closed") {
      // Close the ticket if "closed" is selected
      handleTicketClose(ticket._id);
    }
  };
  const handleSend = () => {
    if (inputValue.trim()) {
      handleSendMessage(ticket, inputValue);  
      setInputValue(""); 
    } else {
      toast.error("Message cannot be empty");
    }
  };
  
  const truncateText = (text, maxLength) =>
    text?.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "25px 0px 0px 25px" } }}
    >
      {ticket && (
        <div className="drawer-content" style={{ width: "283px", padding: "20px",display:'flex',flexDirection:'column',height:'100vh' }}>
          <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
            <div>
              <div style={{ color: "#EA1D26", fontFamily: "Rosario", fontSize: "24px" }}>
                Reply to
              </div>
              <div style={{ color: "#707070", fontFamily: "Rosario" }}>
                {ticket?.chef?.email || "No email available"}
              </div>
            </div>
            <button
              className="close-drawer"
              onClick={onClose}
              style={{ border: "none", background: "none", fontSize: "1.5rem", cursor: "pointer" }}
            >
              X
            </button>
          </div>

          <div className="title" style={{ marginTop: "20px" }}>
          {truncateText(ticket?.title, 40) || "No title available"}
          </div>
          <p>
          {truncateText(ticket?.description, 80) || "No description available"}
            </p>

          <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", marginTop: "20px" ,alignItems:'center'}}>
           
           <div style={{fontWeight:500,fontFamily:'Rosario'}}>
              Priority: <span style={{color:'#EA1D26',fontFamily:'Rosario'}}>High</span>
           </div>
            <div>
              <select
                className="form-select"
                style={{
                  width: "128px",
                  height: "34px",
                  textAlign: "center",
                  borderRadius: "5px",
                  background: "#F6F6F6",
                  border: "1px solid #A0A0A0",
                  color: "#707070",
                }}
                value={ticket?.status || ""}
                onChange={handleStatusChange}
              >
                {ticket?.status === "open" && <option value="open">Open</option>}
                <option value="closed">Resolved</option>
              </select>
            </div>
          </div>

          {/* <div className="chat-section-container" style={{ marginTop: "20px" }}>    
            {Array.isArray(messages) && messages.length > 0 ? (
              messages.map((msg) => (
                <div
                  key={msg._id}
                  style={{
                    display: "flex",
                    justifyContent: msg.from_user[0]?._id === ticket?.chef?._id ? "flex-start" : "flex-end",
                    margin: "10px 0",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: msg.from_user[0]?._id === ticket?.chef?._id ? "#F6F6F6" : "#EA1D26", 
                      color: msg.from_user[0]?._id === ticket?.chef?._id ? "#000" : "#FFF",  
                      padding: "5px",
                      borderRadius: "8px",
                      maxWidth: "70%",  
                    }}
                  >
                    {msg.message}
                  </div>
                </div>
              ))
            ) : (
              <div style={{textAlign:'center',marginTop:'10px'}}>No Messages available.</div>
            )}
          </div> */}

<div className="chat-section-container" style={{ marginTop: "20px" }}>
  {Array.isArray(messages) && messages.length > 0 ? (
    messages.map((msg) => {
      // Extract user roles for alignment logic
      const isAdminMessage = msg.from_user[0]?.role === "Admin";

      return (
        <div
          key={msg._id}
          style={{
            display: "flex",
            justifyContent: isAdminMessage ? "flex-end" : "flex-start",
            margin: "10px 0",
          }}
        >
          <div
            style={{
              backgroundColor: isAdminMessage ? "#EA1D26" : "rgb(219 219 219)", // Red for Admin, Grey for User
              color: isAdminMessage ? "#FFF" : "#000", // White text for Admin, Black for User
              padding: "10px",
              borderRadius:  isAdminMessage ? "18px 18px 18px 0px" : "18px 18px 0px 18px",
              maxWidth: "70%",
            }}
          >
            {msg.message}
          </div>
        </div>
      );
    })
  ) : (
    <div style={{ textAlign: "center", marginTop: "10px" }}>
      No Messages available.
    </div>
  )}
</div>


          {/* {ticket?.status === "open" && (
            <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Enter your message"
                style={{ flex: 1, padding: "8px", marginRight: "10px", borderRadius: "4px" }}
              />
              <div onClick={handleSend} style={{ cursor: "pointer" }}>
                <SendIcon />
              </div>
            </div>
          )}
        </div>
      )} */}

{ticket?.status === "open" && (
            <div
              className="send-chat-reply-btn-container"
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                flexGrow:1
              }}
            >
              <input
                type="text"
                className="chat-input"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Enter your message"
              />
              <div
                className="send-btn-icon"
                onClick={handleSend}
                style={{ cursor: "pointer" }}
              >
                <SendIcon />
              </div>
            </div>
          )}
        </div>
      )}
    </Drawer>
  );
};

export default TicketDrawer;

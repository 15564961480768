import React from "react";

import { Link, useNavigate } from "react-router-dom";
import Card2 from "../../common/card2/Card2";
import './ChefContentManagement.css';

const ChefContentManagement = () => {

const navigate=useNavigate();


  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="home" to="/dashboard/chefs" className="All-color-breakcurm">
      Chef’s
    </Link>,

    <span style={{ fontWeight: "bolder" }}>&nbsp;Content</span>,
  ];

  const cardData = [
    { text: "FAQ", path: "/dashboard/chefs/content-management/faq" },
    { text: "Mission", path: "/dashboard/chefs/content-management/mission" },
    { text: "Terms & Condition", path: "/dashboard/chefs/content-management/terms-and-condition" },
    { text: "Privacy Policy", path: "/dashboard/chefs/content-management/privacy-policy" },
    { text: "Cancellation Policy", path: "/dashboard/chefs/content-management/cancellation-policy" },
  ];


  return (
    <div>
      <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>

     {/* <div className="chef-content-card-wrapper">
     <Card2 onClick={()=>navigate("/dashboard/chefs/content-management/faq")}>
        <div className="content-management-text">
         FAQ
        </div>
      </Card2>
      <Card2 onClick={()=>navigate("/dashboard/chefs/content-management/mission")}>
        <div className="content-management-text">
          Mission
        </div>
      </Card2>
      <Card2 onClick={()=>navigate("/dashboard/chefs/content-management/terms-and-condition")}>
        <div className="content-management-text">
          Terms & Condition
        </div>
      </Card2>
      <Card2 onClick={()=>navigate("/dashboard/chefs/content-management/privacy-policy")}>
        <div className="content-management-text">
        Privacy Policy
        </div>
      </Card2>
      <Card2 onClick={()=>navigate("/dashboard/chefs/content-management/cancellation-policy")}>
        <div className="content-management-text">
        Cancellation Policy
        </div>
      </Card2>
     </div> */}
     <div className="chef-content-card-wrapper">
        {cardData.map((card, index) => (
          <Card2 key={index} onClick={() => navigate(card.path)}>
            <div className="content-management-text">{card.text}</div>
          </Card2>
        ))}
      </div>
    </div>
  );
};

export default ChefContentManagement;

import { TextField, InputAdornment, Menu, MenuItem, Checkbox, ListItemText, MenuList } from '@mui/material';
import SearchItem from '../../common/assest/searchIcon.svg';
import filterIcon from '../../common/assest/filterIcon.svg';
import React, { useState } from 'react';

const SearchWithFilter = ({ style, setSearchQuery, searchQuery, resetPage, setFilter, filter }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedFilters, setSelectedFilters] = useState(filter || []);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = (event) => {
    if (event.target.value.trim() !== "" || event.target.value === "") {
      setSearchQuery(event.target.value);
      resetPage();
    }
  };

  const handleFilterChange = (event, filterType) => {

    //  for multiple slect 
    const newFilters = event.target.checked
      ? [...selectedFilters, filterType]
      : selectedFilters.filter((item) => item !== filterType);


    //  for single select only 
    
    // const newFilters = event.target.checked ? [filterType] : [];
    
    setSelectedFilters(newFilters); // Update the selected filters
    setFilter(newFilters); // Pass the selected filters to the parent component
    resetPage(); 
    handleClose();
  };

  return (
    <>
      <TextField
        value={searchQuery}
        onChange={handleSearchChange}
        placeholder="Search..."
        sx={{
          width: { sm: 300 },
          ...style,
          height: { xs: 45, sm: 45 },
          flexShrink: 0,
          borderRadius: "33px",
          background: "#F6F5F6",
          border: "none",
          "& .MuiOutlinedInput-input": {
            height: "30%",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "33px",
            height: "100%",
            paddingRight: "10px",
            outline: "none",
            border: "none",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img
                src={SearchItem}
                sx={{ color: "#000", opacity: "0.35" }}
                alt="search"
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" onClick={handleClick}>
              <img
                src={filterIcon}
                style={{ cursor: "pointer" }}
                alt="filter"
              />
            </InputAdornment>
          ),
          style: { height: "100%" },
        }}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '25px',
            bgcolor: 'white',
            boxShadow: "none",
            border: "1px solid #B0B0B0",
            width: "180px",
            paddingLeft: '20px',
            marginTop:'14px'
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div style={{ fontFamily: 'Rosario', color: '#EA1D26' ,marginTop:"10px"}}>Subscription</div>
        <MenuItem sx={{ fontFamily: 'Rosario' }}>
          <Checkbox
            checked={selectedFilters.includes("free")}
            onChange={(event) => handleFilterChange(event, "free")}
            color="primary"
          />
          <ListItemText primary="Free" />
        </MenuItem>
        <MenuItem sx={{ fontFamily: 'Rosario' }}>
          <Checkbox
            checked={selectedFilters.includes("premium")}
            onChange={(event) => handleFilterChange(event, "premium")}
            color="primary"
          />
          <ListItemText primary="Premium" />
        </MenuItem>

        <div style={{ fontFamily: 'Rosario', color: '#EA1D26' }}>Account Status</div>
        <MenuItem sx={{ fontFamily: 'Rosario' }}>
          <Checkbox
            checked={selectedFilters.includes("active")}
            onChange={(event) => handleFilterChange(event, "active")}
            color="primary"
          />
          <ListItemText primary="Active" />
        </MenuItem>
        <MenuItem sx={{ fontFamily: 'Rosario' }}>
          <Checkbox
            checked={selectedFilters.includes("inactive")}
            onChange={(event) => handleFilterChange(event, "inactive")}
            color="primary"
          />
          <ListItemText primary="Inactive" />
        </MenuItem>
      </Menu>
    </>
  );
};

export default SearchWithFilter;

import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Box,
  Menu,
  MenuItem,
  Drawer,
  IconButton,
  Divider,
} from "@mui/material";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import StarIcon from "@mui/icons-material/Star";
import CloseIcon from "@mui/icons-material/Close";
import CharMenuIcon from "../assest/charmenuicon.svg";

const ReviewsRow = ({ row, deleteReview, index }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const StarRating = ({ rating }) => {
    const fullStars = Math.floor(rating);
    const decimal = rating - fullStars;
    const stars = [];

    for (let i = 0; i < fullStars; i++) {
      stars.push(
        <StarIcon key={i} sx={{ color: "#FFD700", fontSize: "20px" }} />
      );
    }

    if (decimal > 0) {
      stars.push(
        <StarHalfIcon
          key="partial"
          sx={{ color: "#FFD700", fontSize: "20px" }}
        />
      );
    }

    for (let i = stars.length; i < 5; i++) {
      stars.push(
        <StarOutlinedIcon
          key={i}
          sx={{ color: "#3434344A", fontSize: "20px" }}
        />
      );
    }

    return <div>{stars}</div>;
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const address = row?.eventDetails
  ? `${row.eventDetails.street_address}, ${row.eventDetails.city}, ${row.eventDetails.country}`
  : "-";

  return (
    <>
      <TableRow key={row?._id}>
        <TableCell>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                fontFamily: "Rosario",
                fontWeight: 500,
                fontSize: "17px",
              }}
            >
              {index + 1 || "-"}
            </div>
          </Box>
        </TableCell>

        <TableCell
          sx={{
            fontFamily: "Rosario",
            fontSize: "17px",
          }}
        >
          <div style={{width:'100px'}}>
            {row?.userDetails ? (
              `Review By: ${row?.userDetails.full_name || "-"}`
            ) : (
              <div>{`Review By: ${row?.full_name || "-"}`}</div>
            )}
          </div>
        </TableCell>

        <TableCell
          sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
        >
          <div  style={{width:'100px'}}>{row?.eventDetails.name || "-"}</div>
        </TableCell>

        <TableCell
          sx={{
            fontFamily: "Rosario",
            fontWeight: 500,
            fontSize: "17px",
            // width:{sm:500,md:600}
          }}
        >
          <div style={{width:'500px'}}>{row?.review || "-"}</div>
          <div
            style={{
              color: "#EA1D26",
              fontWeight: "600",
              marginTop: "5px",
              cursor: "pointer",
              width: "100px",
            }}
            onClick={handleDrawerToggle}
          >
            View 2 replies
          </div>
        </TableCell>

        <TableCell
          sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
        >
          <div style={{width:'100px'}}>
            <StarRating rating={row?.rating || 0} />
          </div>
        </TableCell>

        <TableCell
          sx={{
            fontFamily: "Rosario",
            fontWeight: 500,
            fontSize: "17px",
          }}
        >
          <div>
            {row?.created_at
              ? new Date(row?.created_at || "-").toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                })
              : "-"}
          </div>
        </TableCell>

        <TableCell
          sx={{
            fontFamily: "Rosario",
            fontWeight: 500,
            fontSize: "17px",
            cursor: "pointer",
          }}
        >
          <div>
            <img src={CharMenuIcon} alt="icon" onClick={handleClick} />
          </div>
        </TableCell>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "25px",
              bgcolor: "white",
              boxShadow: "none",
              border: "1px solid #B0B0B0",
              padding: "5px",
              width: "140px",
            },
          }}
        >
          <MenuItem
            onClick={deleteReview}
            sx={{ fontFamily: "Rosario", color: "#EA1D26", fontWeight: "400" }}
          >
            Delete Review
          </MenuItem>
        </Menu>
      </TableRow>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            width: "285px",
            paddingLeft: "15px",
            paddingRight: "15px",
          
       
            backgroundColor: "#fff",
          },
        }}
        PaperProps={{ sx: { borderRadius: "25px 0px 0px 25px" } }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center",marginTop:'15px' }}>
          <div style={{ color: "#EA1D26", fontWeight: "700", fontFamily: "Rosario", }}>
            {row?.eventDetails?.name || "N/A"}
          </div>
          <IconButton onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
        </Box>

        <div style={{ color: "#707070", marginTop: "10px",fontFamily: "Rosario" }}>
          {address || "N/A"}
        </div>

        <div style={{ marginTop: "20px",display:'flex',justifyContent:'space-between',alignItems:"center",fontFamily: "Rosario" }}>
          <div>Review by: {row?.userDetails?.full_name || row?.full_name || "-"}</div>
          <div>
            {row?.created_at
              ? new Date(row?.created_at).toLocaleDateString("en-US", {
                  month: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                })
              : "-"}
          </div>
        </div>

        <Box sx={{ marginTop: "10px" }}>
          <StarRating rating={row?.rating || 0} />
        </Box>

        <div style={{ marginTop: "10px" ,fontFamily:'Rosario'}}>{row?.review || "N/A"}</div>

        <Divider sx={{ marginY: "10px" }} />

        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <div style={{ color: "#979797",fontStyle:'italic' }}>Name</div>
          <div style={{fontFamily: "Rosario"}}>
           02/03/2000
          </div>
          
        </div>
        <div style={{fontFamily:'Rosario',color:'#4C4C4C',marginTop:'10px',}}>
                  txt reply
      </div>

        <Divider sx={{ marginY: "10px" }} />
      </Drawer>
    </>
  );
};

export default ReviewsRow;

import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Card1 from "../../common/card1/Card1";
import TableCard from "../../common/tableCard/TableCard";
import TableComp from "../../common/tablecomp/TableComp";
import userimg from "../../common/assest/usrimg.png";
import CustomModal from "../../common/modal/CustomModal";
import ClearIcon from "@mui/icons-material/Clear";
import SearchWithFilter from "../../common/searchbar/SearchWithFilter";
import GuestsRow from "../../common/tablerow/GuestsRow";
import "./Guests.css";
import { useSelector } from "react-redux";
import {
  deletChefApi,
  getGuestsEventsApi,
  guestsListApi,
  suspendChefApi,
} from "../../api";
import Loader from "../../common/loader/Loader";
import { TableCell, TableRow } from "@mui/material";
import CarbonAvtar from "../../common/assest/carbonavatar.svg";
import Paginations from "../../common/pagintaion/Pagination";
import alertDialog from "../../utils/alertDialog";
import { toast } from "react-toastify";
import formatDate from "../../utils/birthDateConversion";

const GuestsDetails = () => {
  const token = useSelector((state) => state?.user?.user?.token);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState({
    active_user: 0,
    active_user_month: 0,
    total_user: 0,
  });
  const [guestData, setGuestData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [viewMoreData, setViewMoreData] = useState("");
  const [change, setChange] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [eventsData, setEventsData] = useState("");
  const [myTimout, setMyTimeOut] = useState();
  const [guestsEventData, setGuestsEventData] = useState([]);
  const [resetting, setResetting] = useState(false); 
  const [filter, setFilter] = useState(""); 


  const handleGuestDetails = async () => {
    try {
      if (!resetting) setLoading(true);
      // setLoading(true);
      const res = await guestsListApi({ token, currentPage, searchQuery,filter });
      setLoading(false);
      setGuestData(res?.data);
      setCurrentPage(res?.page);
      setTotalPage(res?.pages);
      setTotalCount({
        active_user: res?.active_user_count || 0,
        active_user_month: res?.total_count_user_current_month || 0,
        total_user: res?.total_count_user || 0,
      });
    } catch (error) {
      setLoading(false);
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    handleGuestDetails();
  }, [currentPage, change,filter]);

  useEffect(() => {
    if (searchQuery.trim() == "") {
      handleGuestDetails();
    } else {
      clearTimeout(myTimout);
      setMyTimeOut(setTimeout(handleGuestDetails, 500));
    }
  }, [searchQuery]);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
    setOpen1(false);
  };

  const openModal = (item) => {
    setViewMoreData(item);
    setOpen(true);
  };
  const openModal1 = (item) => {
    handleGuestsEvents(item?._id);
    setEventsData(item);
    setOpen1(true);
  };

  const handleDeleteUser = async (id) => {
    const confirmed = await alertDialog({
      title: "Confirm Action",
      description: "Are you sure you want to Delete this User?",
    });
    if (!confirmed) {
      return;
    }
    try {
      const response = await deletChefApi({ token, id });
      toast.success(response?.message);
      setChange(!change);
    } catch (error) {
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  const handleSuspendUser = async (id, item) => {
    const msg = item?.is_banned ? "Resume" : "Suspend";
    const confirmed = await alertDialog({
      title: "Confirm Action",
      description: `Are you sure you want to ${msg} this User?`,
    });
    if (!confirmed) {
      return;
    }
    try {
      const response = await suspendChefApi({ token, id });
      toast.success(response?.message);
      setChange(!change);
    } catch (error) {
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

 

  const handleGuestsEvents = async (id) => {
    try {
      setLoading(true);
      const response = await getGuestsEventsApi({ token, id });
      setGuestsEventData(response?.events);

      setLoading(false);
      // setCurrentPage(response?.page);
      // setTotalPage(response?.pages);
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  const style = {
    minHeight: "95px",
    paddingTop: "22px",
    cursor: "default",
  };

  const titles = [
    { title: "Guests Name" },
    { title: "Email" },
    { title: "Address" },
    { title: "Attended Events" },
    { title: "Joined Date" },
    { title: "Subscription " },
    { title: "Account Status " },
  ];

  

  const handlePageChange = (page) => {
    setCurrentPage(page); 
  };

  const resetPage = () => {
    setResetting(true); 
    setCurrentPage(1);
  };


  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="home" to="/dashboard/guests" className="All-color-breakcurm">
      Guests
    </Link>,

    <span style={{ fontWeight: "bolder" }}>&nbsp;Guest’s Details</span>,
  ];

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="bread-crum">
            {breadcrumbItems.map((item, index) => (
              <div className="all-breakcrum" key={index}>
                {item}
                {index < breadcrumbItems.length - 1 && " > "}
              </div>
            ))}
          </div>

          <div className="chef-details-card-container">
            <Card1
              value={totalCount?.total_user || 0}
              text="Total Guests"
              style={style}
            />
            <Card1
              value={totalCount?.active_user_month || 0}
              text="Total Guests This Month"
              style={style}
            />
            <Card1
              value={totalCount?.active_user || 0}
              text="Active Guests Currently on App"
              style={style}
            />
          </div>

          <div className="guests-table">
            <TableCard>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                  padding: "20px",
                  fontFamily: "Rosario",
                  fontWeight: 600,
                  fontSize: "20px",
                  gap: "10px",
                }}
              >
                <div>Guest’s Details</div>
                <div>
                  <SearchWithFilter
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    resetPage={resetPage}
                    filter={filter}
                    setFilter={setFilter}
                  />
                </div>
              </div>

              <TableComp title={titles} emptyEColumnCount={2}  currentPage={currentPage}
              totalPage={totalPage}
              onPageChange={handlePageChange}
              loading={loading}>

                {guestData?.length > 0 ? (
                  guestData.map((item) => (
                    <GuestsRow
                      // handleViewMore={() => openModal(item)}
                      handleEvents={() => openModal1(item)}
                      row={item}
                      key={item?._id}
                      deleteUser={() => handleDeleteUser(item?._id)}
                      suspendUser={() => handleSuspendUser(item?._id)}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={titles.length}
                      align="center"
                      sx={{ fontFamily: "Rosario", fontWeight: "600" }}
                    >
                      No Data Found
                    </TableCell>
                  </TableRow>
                )}

              
              </TableComp>
            </TableCard>
          </div>

          {/* <CustomModal open={open} handleClose={handleClose}>
        <div
          onClick={handleClose}
          style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            color: "#6E6E6E",
            cursor: "pointer",
          }}
        >
          <ClearIcon />
        </div>

        <div className="img-mail-name-container">
            <div><img src={viewMoreData?.profile_picture||CarbonAvtar}/></div>
            <div className="name-email-container">
                <div  style={{fontFamily:'Rosario'}}>{viewMoreData?.full_name||"N/A"}</div>
                <div style={{color:'#868686',fontFamily:'Rosario'}}>{viewMoreData?.email||"N/A"}</div>
            </div>
          </div>

        <div className="modal-txt">Birthdate:<span> 
          {viewMoreData?.date_of_birth
                  ? formatDate(viewMoreData.date_of_birth)
                  : "N/A"}
                  
                  </span> </div>
        <div className="modal-txt">Category:<span> 
        {viewMoreData?.preferred_categories && viewMoreData.preferred_categories.length > 0
                ? viewMoreData.preferred_categories.map((language, index) => (
                    <span key={index}>
                      {language}
                      {index < viewMoreData.preferred_categories.length - 1 ? ', ' : ''}
                    </span>
                  ))
                : "N/A"}
            
           </span> </div>
        <div className="modal-txt">Beverage Preference: <span> {viewMoreData?.is_alcoholic ? "Alcoholic" :" Non Alcoholic" }</span> </div>
        <div className="modal-txt">Dining Style:<span> 
        {viewMoreData?.preferred_dinning && viewMoreData.preferred_dinning.length > 0
                ? viewMoreData.preferred_dinning.map((language, index) => (
                    <span key={index}>
                      {language}
                      {index < viewMoreData.preferred_dinning.length - 1 ? ', ' : ''}
                    </span>
                  ))
                : "N/A"}
           </span> </div>
      </CustomModal> */}

          <CustomModal open={open1} handleClose={handleClose}>
            <div
              onClick={handleClose}
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "end",
                color: "#6E6E6E",
                cursor: "pointer",
              }}
            >
              <ClearIcon />
            </div>
            {guestsEventData.length > 0
              ? guestsEventData.map((item) => (
                  <>
                    <div className="modal-txt" style={{ fontSize: "24px" }}>
                      {item?.event_name || "N/A"}
                      <p>{item?.address || "N/A"} </p>{" "}
                    </div>
                    
                  </>
                ))
              : "No Events Found"}
          </CustomModal>

          <div className="txt-sample">
            Copyright © 2024 Chef’s Table All rights reserved.
          </div>
        </div>
      )}
    </>
  );
};

export default GuestsDetails;

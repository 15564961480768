import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { chefChatDataApi, chefChatMessageApi, chefChatTicketCloseApi, getAllChefsTicketApi } from '../../api';
import { toast } from 'react-toastify';
import Loader from '../../common/loader/Loader';
import AllTicket from '../../common/Ticket/AllTicket';
import UnResolved from '../../common/Ticket/UnResolved';
import Resolved from '../../common/Ticket/Resolved';
import Paginations from '../../common/pagintaion/Pagination';
import TicketDrawer from '../../common/Ticket/TicketDrawer';
import alertDialog from '../../utils/alertDialog';
// import TicketDrawer from '../../common/drawer/TicketDrawer'; // Assuming you have a drawer component

const ChefTicketManagement = () => {
  const token = useSelector((state) => state?.user?.user?.token);
  const adminId = useSelector((state) => state?.user?.user?._id); 
  const [ticketsData, setTicketsData] = useState({
    all: { data: [], total: 0, totalPages: 0, currentPage: 1 },
    unresolved: { data: [], total: 0, totalPages: 0, currentPage: 1 },
    resolved: { data: [], total: 0, totalPages: 0, currentPage: 1 },
  });

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [limits, setLimits] = useState({ all: 10, unresolved: 10, resolved: 10 });
  const [selectedTicket, setSelectedTicket] = useState(null); // Store the selected ticket
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // To control drawer visibility
  const [messages, setMessages] = useState([]); 
  const [change,setChange]=useState(false)

  const fetchTickets = async (status = '', page = 1, limit = 10) => {
    try {
      setLoading(true);
      const response = await getAllChefsTicketApi({ token, currentPage: page, status, limit });
      const ticketKey = status === '' ? 'all' : status.toLowerCase();
      setTicketsData((prev) => ({
        ...prev,
        [ticketKey]: {
          data: response?.data || [],
          total: response?.total || 0,
          totalPages: response?.pages || 0,
          currentPage: response?.page || 1,
        },
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  useEffect(() => {
    const statusMap = ['', 'Unresolved', 'Resolved'];
    const activeStatus = statusMap[activeTab];
    const { currentPage } = ticketsData[activeStatus.toLowerCase() || 'all'];
    const limit = limits[activeStatus.toLowerCase() || 'all'];

    fetchTickets(activeStatus, currentPage, limit);
  }, [activeTab,change]);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const handlePageChange = (tabKey, page) => {
    setTicketsData((prev) => ({
      ...prev,
      [tabKey]: {
        ...prev[tabKey],
        currentPage: page,
      },
    }));

    const statusMap = { all: '', unresolved: 'Unresolved', resolved: 'Resolved' };
    const limit = limits[tabKey];
    fetchTickets(statusMap[tabKey], page, limit);
  };

  const handleTicketSelect = (ticket) => {
    setSelectedTicket(ticket);  // Set the selected ticket
    setIsDrawerOpen(true); // Open the drawer
  };

  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="chefs" to="/dashboard/chefs" className="All-color-breakcurm">
      Chef’s
    </Link>,
    <span key="ticket-management" style={{ fontWeight: 'bolder' }}>
      &nbsp;Ticket Management
    </span>,
  ];

  const renderTabContent = () => {
    if (activeTab === 0) return <AllTicket allTicketData={ticketsData?.all?.data} onTicketSelect={handleTicketSelect} />;
    if (activeTab === 1) return <UnResolved unresolvedTickets={ticketsData?.unresolved?.data} onTicketSelect={handleTicketSelect} />;
    if (activeTab === 2) return <Resolved resolvedTickets={ticketsData?.resolved?.data} onTicketSelect={handleTicketSelect} />;
  };

  const renderPagination = () => {
    const tabKeys = ['all', 'unresolved', 'resolved'];
    const tabKey = tabKeys[activeTab];
    const { currentPage, totalPages } = ticketsData[tabKey];
    return (
      <Paginations
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => handlePageChange(tabKey, page)}
        disabled={loading}
      />
    );
  };

  const handleMessageData = async (ticketId) => {
    try {
      const response = await chefChatDataApi({ token, ticket: ticketId });
      // console.log("check msg",response)
      setMessages(response?.data || []);
    } catch (error) {
      const errorMessage = error?.response?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };


  const handleSendMessage = async (ticket, message) => {
    try {
      const messagePayload = {
        ticket: ticket?._id,
        message,
      };
      const res = await chefChatMessageApi({ token, messagePayload });
      handleMessageData(ticket?._id); 
    } catch (error) {
      const errorMessage = error?.response?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };


  const handleTicketClose = async (ticketId) => {
    const confirmed = await alertDialog({
      title: 'Confirm Action',
      description: 'Are you sure you want to Close this Ticket?'
    });
    if(!confirmed){
      return
    }
    try {
      const response = await chefChatTicketCloseApi({ token, id: ticketId });
      toast.success("Ticket Closed Successfully");
      setIsDrawerOpen(false); 
      setChange(!change)
    } catch (error) {
      const errorMessage = error?.response?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="bread-crum">
            {breadcrumbItems.map((item, index) => (
              <div className="all-breakcrum" key={index}>
                {item}
                {index < breadcrumbItems.length - 1 && ' > '}
              </div>
            ))}
          </div>

          <div className="ticket-management-btn-container">
            <div className="five-btn-container">
              {['All', 'Unresolved', 'Resolved'].map((label, index) => (
                <button
                  key={label}
                  onClick={() => handleTabChange(index)}
                  className={`responsive-btn ${activeTab === index ? 'active' : ''}`}
                  style={{
                    width: '198px',
                    height: '65px',
                    borderRadius: '100px',
                    textTransform: 'none',
                    border: '1px solid #DCDCDC',
                    backgroundColor: activeTab === index ? '#EA1D26' : '#FFF',
                    color: activeTab === index ? '#FFF' : '#C6C6C6',
                    cursor: 'pointer',
                  }}
                >
                  {label} Tickets ({ticketsData[label.toLowerCase()]?.total || 0})
                </button>
              ))}
            </div>
            {renderTabContent()}
          </div>

          {renderPagination()}

          {/* Drawer component */}
          {isDrawerOpen && (
             <TicketDrawer
             isOpen={isDrawerOpen}
             ticket={selectedTicket}
             onClose={() => setIsDrawerOpen(false)}
             handleSendMessage={handleSendMessage}
             handleTicketClose={handleTicketClose}
             handleMessageData={handleMessageData}
             setMessages={setMessages} 
             messages={messages}
           />
          )}
        </>
      )}
    </div>
  );
};

export default ChefTicketManagement;

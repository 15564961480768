import React, { useState, useRef } from "react";
import RulesRow from "../../common/tablerow/RulesRow";
import SearchBar from "../../common/searchbar/SearchBar";
import TableCard from "../../common/tableCard/TableCard";
import { Button, TableCell, TableRow } from "@mui/material";
import TableComp from "../../common/tablecomp/TableComp";
import { Link } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import RulesModal from "../../common/modal/RulesModal";
import CustomBtn1 from "../../common/customBtn1/CustomBtn1";
import FoodRow from "../../common/tablerow/FoodRow";
import UploadIcon from '../../common/assest/solar_upload-bold.png';

const FoodSpecialty = () => {

    const [searchQuery,setSearchQuery]=useState("")
    const [resetting,setResetting]=useState(false)
  

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null); // Ref for the file input

  const handleCreateRule = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null); // Clear selected image when modal is closed
  };

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setSelectedImage({ file, imageURL });
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
  };

  const RulesData = [{}];

  const styles = {
    width: "275px",
  };

  const titles = [
    { title: "Sr No" },
    { title: "Food Specialty Image" },
    { title: "Food Specialty Name" },
    { title: "Action" },
  ];

  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Food Speciality </span>,
  ];


  
  const handleSearchChange = (newValue) => {
    setSearchQuery(newValue);
  };

  const resetPage = () => {
    setResetting(true); 
    // setCurrentPage(1);
  };

  return (
    <div>
      <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>

      <div className="whole-card-wrapper-notification">
        <TableCard>
          <div className="push-notification-txt">Food Speciality </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center",
              padding: "20px",
              gap: "10px",
            }}
          >
            <div>
              <SearchBar style={styles} placeholder="Search..." onSearchChange={handleSearchChange} resetPage={resetPage}/>
            </div>

            <div>
              <Button
                onClick={handleCreateRule}
                sx={{ width: "220px" }}
                variant="contained"
                className="create-admin-btn"
              >
                Create New Specialty
              </Button>
            </div>
          </div>

          <TableComp title={titles}>
            {RulesData.length > 0 ? (
              RulesData.map((item, idx) => (
                <FoodRow
                  key={item?._id}
                  index={idx}
                  row={item}
                />
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={titles.length}
                  align="center"
                  sx={{ fontFamily: "Rosario", fontWeight: "600" }}
                >
                  No Data Found
                </TableCell>
              </TableRow>
            )}
          </TableComp>
        </TableCard>
      </div>

      <RulesModal open={open} handleClose={handleClose}>
        <div className="create-modal-heading">
          <div>{"Create New Food Specialty "}</div>
          <ClearIcon onClick={handleClose} style={{ cursor: "pointer" }} />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          {selectedImage ? (
            <div style={{ position: "relative", display: "inline-block" }}>
              <img
                src={selectedImage.imageURL}
                alt="Selected"
                style={{ width: "140px",height:"80px", borderRadius: "10px" }}
              />
              <ClearIcon
                onClick={handleRemoveImage}
                style={{
                  position: "absolute",
                  top: "-5px",
                  right: "-5px",
                  cursor: "pointer",
                  backgroundColor: "#EA1D26",
                  color:"#fff",
                  borderRadius: "50%",
                }}
              />
            </div>
          ) : (
            <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleImageSelect}
                accept="image/*"
              />
              <img
                src={UploadIcon}
                style={{ width: "40px", cursor: "pointer" }}
                onClick={() => fileInputRef.current.click()} 
                alt="Upload Icon"
              />
              <div
                style={{
                  fontFamily: "Rosario",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => fileInputRef.current.click()} 
              >
                Upload Image
              </div>
            </div>
          )}
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <textarea
            placeholder="Enter here"
            rows={3}
            cols={89}
            style={{
              width: "90%",
              border: "none",
              backgroundColor: "#F0F0F0",
              borderRadius: "25px",
              resize: "none",
              outline: "none",
              textIndent: "20px",
              paddingTop: "20px",
              fontWeight: "400",
              color: "#120B1C",
              fontFamily: "Rosario",
            }}
          />
        </div>

        <div style={{ marginTop: "30px", marginBottom: "30px" }}>
          <div
            style={{
              width: "90%",
              display: "flex",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <CustomBtn1 text="Add" />
          </div>
        </div>
      </RulesModal>
    </div>
  );
};

export default FoodSpecialty;

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Card1 from "../../common/card1/Card1";
import {
  Button,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import PdfIcon from "../../common/assest/pdf-icon.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { deletChefApi, particularChefDetailApi, suspendChefApi } from "../../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import alertDialog from "../../utils/alertDialog";
import CarbonAvtar from "../../common/assest/carbonavatar.svg";
import formatDate from "../../utils/birthDateConversion";
import Loader from "../../common/loader/Loader";


const ChefPersonalInfo = () => {
  const token = useSelector((state) => state?.user?.user?.token);
  const {state}=useLocation();
 
  const {id}=useParams()
  const navigate = useNavigate();
  const [chefsData,setChefsData]=useState({})
  const [change,setChange]=useState(false)
  const [loading,setLoading]=useState(false)

  
  const [showCreateNotification, setShowCreateNotification] = useState(false);
  const [status,setStatus]=useState(chefsData?.is_banned)
 const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreateNotification = () => {
    setShowCreateNotification(true);
  };


  const handleChefParticularDetails=async()=>{
    try {
      setLoading(true)
      const response=await particularChefDetailApi({token,chefId:id})
      setChefsData(response)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  }
  
useEffect(()=>{
  handleChefParticularDetails()
},[])

  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">Home</Link>,
    <Link key="chefs" to="/dashboard/chefs" className="All-color-breakcurm">Chef’s</Link>,
    <Link key="chefs-details" to="/dashboard/chefs/all-chefs" className="All-color-breakcurm">Chef’s Details</Link>,
    <span key="chef-name" style={{ fontWeight: "bolder" }}>&nbsp;{chefsData?.full_name||"Chef's"}</span>,
  ];

  const style = {
    minHeight: "95px",
    paddingTop: "22px",
    cursor: "pointer",
  };

 
  
  const handleDeleteUser=async(id)=>{
    const confirmed = await alertDialog({
      title: 'Confirm Action',
      description: 'Are you sure you want to Delete this User?'
    });
    if(!confirmed){
      return
    }
    try {
      const response=await deletChefApi({token,id})
      toast.success(response?.message)
      setAnchorEl(null);
    } catch (error) { 
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  }
// suspend /reseume on same api
  const handleSuspendUser=async(id)=>{
    // console.log("h",id);
    
    const actionMessage = status ? "Resume" : "Suspend";
    const confirmed = await alertDialog({
      title: 'Confirm Action',
      description: `Are you sure you want to ${actionMessage} this User?`
    });
    if(!confirmed){
      return
    }
    try {
      const response=await suspendChefApi({token,id})
      toast.success(response?.message)
      setStatus((prev) => !prev);
      setAnchorEl(null);
    } catch (error) {
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  }
  
  return (
    <div>
      {loading && <Loader/>}
      <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>

      <div className="chef-details-card-container" style={{ display: "flex"}}>
        <div style={{gap: "20px",flexWrap: "wrap", display:'flex',width: "50%",flexGrow: 1}} className="chef-personal-card-style">
        <Card1
          value="$2000.00"
          text="Total Earnings >"
          style={style}
          onClick={() => navigate(`/dashboard/chefs/all-chefs/chefs-information/chefs-earnings/${chefsData?.chef_id}`,{ state: { chefsData } })}
        />
        <Card1
          value={chefsData?.average_rating ?  parseFloat(chefsData?.average_rating).toFixed(2):0}
          value1={`${chefsData?.total_reviews||0} reviews`}
          text="Total Reviews  >"
          style={style}
          onClick={() => navigate(`/dashboard/chefs/all-chefs/chefs-information/chefs-reviews/${chefsData?.chef_id}`,{ state: { chefsData } })}
        />
        <Card1
          onClick={() => navigate(`/dashboard/chefs/all-chefs/chefs-information/chefs-events/${chefsData?.chef_id}`,{ state: { chefsData } })}
          value={chefsData?.event_count||0}
          text="Total Events  >"
          style={style}
        />
        </div>
        <div >
          <Button
            sx={{ width: "190px", fontFamily: "Rosario",cursor:'pointer'}}
            onClick={handleClick}
            variant="contained"
            className="create-admin-btn"
            endIcon={<ArrowDropDownIcon className="create-admin-icon" />}
          >
            More Options
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
            sx={{
                '& .MuiPaper-root': {
                  borderRadius: '25px', 
                  bgcolor: 'white',
                  boxShadow:"none",
                  border: "1px solid #B0B0B0",
                  padding:'5px',
                  width:"180px"
                },
              }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
            <MenuItem onClick={()=>handleSuspendUser(chefsData?.user_id)} sx={{fontFamily:'Rosario'}}>  
             {status ? "Resume Account" : "Suspend  Account"}</MenuItem>
            <MenuItem onClick={()=>handleDeleteUser(chefsData?.user_id)} sx={{fontFamily:'Rosario',color:'#EA1D26',fontWeight:'400'}}>Delete Account</MenuItem>
        </Menu>
        </div>
      </div>

      <div style={{ backgroundColor: "#E8E8E8", height: "5px", width: "100%", marginTop: "-40px" }} className="hr-lines"/>

      <div className="personal-info-chefs">
        <Grid container spacing={6} justifyContent="center">
          {/* Left Side: Image Uploader */}
          <Grid item xs={12} sm={12} md={2}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
              <div   style={{ width: "140px", height: "140px", borderRadius: "50%", border: "1px solid #EA1D26",}}> 
                  <img src={chefsData?.profile_picture||CarbonAvtar}  style={{ width: "140px", height: "140px", borderRadius: "50%",objectFit:'fit' }}/>

                </div>
              <div style={{ wordBreak: "break-word", marginTop: "10px",fontFamily: "Rosario",}}>{chefsData?.full_name||"N/A"}</div>
              <p style={{ wordBreak: "break-word",fontFamily: "Rosario", }}>{chefsData?.email||"N/A"}</p>
            </div>
          </Grid>

          {/* Right Side: Input Fields */}
          <Grid item xs={12} sm={12} md={10}>
            <div style={{ fontFamily: "Rosario", fontWeight: 700, marginBottom: "20px", marginLeft: "10px" }}>
              Basic Details
            </div>
            <Grid container spacing={2}>
              {/* First Name and Email */}

              {/* <Grid item xs={12} sm={6}>
                <Typography className="acc-management-text">Full Name</Typography>
                <input type="text" placeholder="Enter your Name" name="Full Name" className="chef-common-input" readOnly  value={chefsData?.full_name||"N/A"}/>

                <Typography className="acc-management-text" sx={{ marginTop: "10px" }}>Email Id</Typography>
                <input type="email" placeholder="Enter your Email" name="email" className="chef-common-input" readOnly
                 value={chefsData?.email||"N/A"} />
              </Grid> */}

<Grid item xs={12} sm={6} md={6}>
      <Typography className="acc-management-text">Full Name</Typography>
      <input
        type="text"
        placeholder="Enter your Name"
        name="Full Name"
        className="chef-common-input"
        readOnly
        value={chefsData?.full_name || "N/A"}
      />
    </Grid>

    <Grid item xs={12} sm={6} md={6}>
      <Typography className="acc-management-text">Years of Experience</Typography>
      <input
        type="number"
        placeholder="Years of Experience"
        name="experience"
        className="chef-common-input"
        readOnly
        value={chefsData?.experience_years || "0"}
      />
    </Grid>




              {/* Last Name and Phone Number */}
              {/* <Grid item xs={12} sm={6}> */}
                {/* <Typography className="acc-management-text">Last Name</Typography>
                <input type="text" placeholder="Enter Last Name" name="lastname" className="chef-common-input" readOnly /> */}

                {/* <Typography className="acc-management-text" >Years of Experience</Typography>
                <input type="number" placeholder="Years of Experience" name="number" className="chef-common-input" readOnly value={chefsData?.experience_years||"0"}/> */}
              {/* </Grid> */}

              <Grid item xs={12}>
      <Typography className="acc-management-text" sx={{ marginTop: "10px" }}>Email Id</Typography>
      <input
        type="email"
        placeholder="Enter your Email"
        name="email"
        className="chef-common-input"
        readOnly
        value={chefsData?.email || "N/A"}
        style={{ width: "100%" }} // Makes email input span full width
      />
    </Grid>

              {/* Address */}
              <Grid item xs={12}>
                <Typography className="acc-management-text">Address</Typography>
               
                 <input className="chef-common-input" style={{ width: "100%" }} readOnly
                  value={
                    chefsData?.address && chefsData?.address.length > 0
                      ? `${chefsData.address[0].street_address}, ${chefsData.address[0].city}, ${chefsData.address[0].chefsData} ${chefsData.address[0].zipcode}`
                      : "N/A"
                  }
                />
              </Grid>

              {/* Textarea */}
              <Grid item xs={12}>
                <Typography className="acc-management-text">Your Bio</Typography>
                <textarea
                  rows={6}
                  className="chef-common-input"
                  style={{ resize: "none", textIndent: "20px", paddingTop: "20px", minHeight: "100px", width: "100%" }}
                  readOnly
                  value={chefsData?.bio||"N/A"}
                />
              </Grid>

              {/* Birthdate and Specialty Fields Side by Side */}
              <Grid item xs={12} sm={6}>
                <Typography className="acc-management-text">Birthdate</Typography>
                <input type="text" placeholder="Birthdate" name="birthdate" className="chef-common-input" readOnly
                value={chefsData?.date_of_birth
                  ? formatDate(chefsData.date_of_birth)
                  : "N/A"}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography className="acc-management-text">Specialty</Typography>
                <input type="text" placeholder="Specialty" name="specialty" className="chef-common-input" readOnly
                value={chefsData?.specialities||"N/A"}
                />
              </Grid>

              {/* Cooking Style and Language */}
              <Grid item xs={12} sm={12}>
                <Typography className="acc-management-text">Cooking Style</Typography>
                <input className="chef-common-input" style={{ width: "100%" }} readOnly value={chefsData?.cooking_style||"N/A"} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography className="acc-management-text">Language</Typography>
                <input type="text" placeholder="Language" name="language" className="chef-common-input" readOnly 
                value={chefsData?.languages_spoken||"N/A"}
                />
              </Grid>

              {/* Subscription and Food Handler Section */}
              <Grid item xs={12} sm={6}>
                <Typography className="acc-management-text">Subscription</Typography>
                <input type="text" placeholder="Subscription" name="subscription" className="chef-common-input" readOnly 
                 value={chefsData?.subscription||"N/A"}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography className="acc-management-text">Food Handler Section</Typography>
                <input className="chef-common-input" style={{ width: "100%" }} readOnly 
                value={chefsData?.foodhanlde||"N/A"}
                />
              </Grid>

              <Grid item xs={12} sx={{ marginTop: "10px", color: "#EA1D26", fontFamily: "Rosario", paddingLeft: "10px", fontWeight: 600 }}>
                {/* Portfolio: */}
                <div className="modal-txt">
                  Portfolio:
                  {chefsData?.portfolio ? ( 
                    <div className="whole-pdf">
                      <a
                        href={chefsData.portfolio}
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="portfoliodesign-container"
                        style={{ textDecoration: 'none', color: 'inherit' }} 
                      >
                        <div className="pdf-logo-container">
                          <img alt="pdf-img-logo" src={PdfIcon} />
                        </div>
                        <div>
                          <div style={{color:'#000',fontSize:'14px',marginTop:'10px'}}>{chefsData?.portfolio_name || 'pdf-document'}</div>
                          {/* <span className="portfolio-file-size">
                            {modalData.portfolio_size || 'File Size'}
                          </span> */}
                        </div>
                      </a>
                    </div>
                  ) : (

                    <span style={{color:'#000'}}>
                     {" "} No Portfolio Available
                      </span>
                  )}
                </div>

              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
          <Button
          onClick={()=>handleSuspendUser(chefsData?.user_id)}
            sx={{
              width: "230px",
              fontFamily: "Rosario",
              border: "1px solid #5B5B5B",
              height: "50px",
              borderRadius: "100px",
              color: "#5B5B5B",
            }}
          >

          {status ? "Resume Account" : "Suspicious Account"}
            {/* Suspicious Account */}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChefPersonalInfo;

import React from 'react'
import { Link } from 'react-router-dom';
import AddEditPlan from '../../common/AddEditPlans/AddEditPlan';

const GuestAddSub = () => {

      const breadcrumbItems = [
            <Link key="home" to="/dashboard" className="All-color-breakcurm">
              Home
            </Link>,
            <Link key="home" to="/dashboard/guests" className="All-color-breakcurm">
             Guest's 
          </Link>,
            <Link key="home" to="/dashboard/guests/subscription-management" className="All-color-breakcurm">
            Subscription
          </Link>,
            <Link key="home" to="/dashboard/guests/subscription-management/manage-your-plans" className="All-color-breakcurm">
            Manage Your Plans
          </Link>,
            
            <span style={{ fontWeight: "bolder" }}>&nbsp;Add Subscription</span>,
          ];

  return (
    <div>
     <div className="bread-crum">
            {breadcrumbItems.map((item, index) => (
              <div className="all-breakcrum" key={index}>
                {item}
                {index < breadcrumbItems.length - 1 && " > "}
              </div>
            ))}
          </div>

          <AddEditPlan/>
    </div>
  )
}

export default GuestAddSub